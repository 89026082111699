import { PlanFeatureBlock } from '@/components/pages/membership/plans/PlanCardBlock'
import { DSBadge, DSDivider, DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import { Image } from 'react-datocms'
import styled from 'styled-components'

const StyledFeatureContainer = styled.div<{ $excluded?: boolean }>`
  display: flex;
  gap: var(--grid-12);
  ${({ $excluded }) => $excluded && 'filter: grayscale(100%); opacity: 0.6;'}

  img {
    border-radius: var(--radius-8);
    height: 100%;
  }
`

type PlanCardFeatureGroupProps = {
  title: string
  features: PlanFeatureBlock[]
}
export const PlanCardFeatureGroup = ({ title, features }: PlanCardFeatureGroupProps) => {
  return (
    <>
      <DSSpacer size={24} direction="vertical" />
      <DSText variant="fluid-eyebrow-100" as="span">
        {title}
      </DSText>
      <DSSpacer size={24} direction="vertical" />
      {features.map((feature, index) => (
        <div key={index}>
          <StyledFeatureContainer $excluded={feature.excluded}>
            <Image data={feature.image?.responsiveImage} style={{ height: '100%' }} />
            <div>
              <DSFlex direction="row" justifyContent="space-between">
                <DSText variant="fluid-paragraph-200" as="p" weight="semiBold">
                  {feature.title}
                </DSText>
                {feature.badge && (
                  <>
                    <DSSpacer size={8} direction="horizontal" />
                    <DSBadge surfaceColor="teal-medium" size="small">
                      {feature.badge}
                    </DSBadge>
                  </>
                )}
              </DSFlex>
              <DSSpacer size={2} direction="vertical" />
              <DSText variant="fluid-label-100" as="p" weight="light">
                {feature.description}
              </DSText>
            </div>
          </StyledFeatureContainer>
          <DSSpacer size={24} direction="vertical" />
        </div>
      ))}
      <DSDivider />
    </>
  )
}
