import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { useEffect, useState } from 'react'
import { trackD30PDPViewEvent } from '@/lib/gtm/gtmV2'
import { D30PricingCard } from './D30PricingCard'
import { CheckoutCountry } from '@/lib/checkout/types'

enum ProductsEnum {
  GB_DAILY30_4_MONTH = 'GB_DAILY30_4_MONTH',
  GB_DAILY30_1_MONTH = 'GB_DAILY30_1_MONTH',
  US_DAILY30_4_MONTH = 'US_DAILY30_4_MONTH',
  US_DAILY30_1_MONTH = 'US_DAILY30_1_MONTH',
}

type D30ProductType = {
  id: ProductsEnum
  name: string
  price: number
  currency?: 'gbp' | 'usd'
  previousPrice?: number
  title: string
  ctaText?: string
  featureList?: string[]
  tagline?: string
}

const DAILY30_PRODUCTS: Record<CheckoutCountry, { oneMonth: D30ProductType; fourMonth: D30ProductType }> = {
  GB: {
    fourMonth: {
      id: ProductsEnum.GB_DAILY30_4_MONTH,
      name: 'Daily30 4 Month Subscription',
      price: 13300,
      previousPrice: 15600,
      currency: 'gbp',
      title: '4-month subscription',
      ctaText: 'Subscribe now',
      featureList: [
        '120 daily servings',
        'Delivery every 4 months',
        'Free ZOE tin and magnetic scoop',
        '£1.10 per serving',
        'Change or cancel anytime',
      ],
      tagline: 'ZOE members get an extra 15% off',
    },
    oneMonth: {
      id: ProductsEnum.GB_DAILY30_1_MONTH,
      name: 'Daily30 1 Month Subscription',
      price: 3900,
      currency: 'gbp',
      title: '1-month subscription',
      ctaText: 'Subscribe now',
      featureList: [
        '30 daily servings',
        'Delivery every 30 days',
        'Free ZOE tin and magnetic scoop',
        '£1.30 per serving',
        'Change or cancel anytime',
      ],
      tagline: 'ZOE members get an extra 15% off',
    },
  },
  US: {
    fourMonth: {
      id: ProductsEnum.US_DAILY30_4_MONTH,
      name: 'Daily30 4 Month Subscription',
      price: 22000,
      previousPrice: 26000,
      currency: 'usd',
      title: '4-month subscription',
      ctaText: 'Subscribe now',
      featureList: [
        '4 x 500g pouches',
        '120 daily servings',
        'Free ZOE tin and magnetic scoop',
        'Free shipping',
        'Auto renews until cancelled. Cancel or change any time',
      ],
      tagline: 'ZOE members get an extra 15% off',
    },
    oneMonth: {
      id: ProductsEnum.US_DAILY30_1_MONTH,
      name: 'Daily30 1 Month Subscription',
      price: 6500,
      currency: 'usd',
      title: '1-month subscription',
      ctaText: 'Subscribe now',
      featureList: [
        '500g pouch',
        '30 daily servings',
        'Free ZOE tin and magnetic scoop',
        'Free shipping',
        'Auto renews until cancelled. Cancel or change any time',
      ],
      tagline: 'ZOE members get an extra 15% off',
    },
  },
}

interface D30PriceSelectorProps {
  disabled?: boolean
  config?: any
  country: CheckoutCountry
}

export const D30PriceSelectorBlock = ({ disabled, config, country }: D30PriceSelectorProps) => {
  const products = {
    fourMonth: DAILY30_PRODUCTS[country].fourMonth,
    oneMonth: DAILY30_PRODUCTS[country].oneMonth,
  }

  // DatoCMS config
  const fourMonthProductConfig = config ? config[`PRODUCT_${country}_DAILY30_4_MONTH`] : null
  const oneMonthProductConfig = config ? config[`PRODUCT_${country}_DAILY30_1_MONTH`] : null

  const [selectedCard, setSelectedCard] = useState<D30ProductType>(products.fourMonth)

  useEffect(() => {
    if (!disabled) {
      setSelectedCard(products.fourMonth)
      window.sessionStorage.setItem('d30SelectedProduct', products.fourMonth.id)
    }
  }, [disabled])

  useEffect(() => {
    trackD30PDPViewEvent({ productName: selectedCard.name, productPrice: selectedCard.price, itemId: selectedCard.id })
  }, [selectedCard])

  const handleClick = (product: D30ProductType) => {
    setSelectedCard(product)
    window.sessionStorage.setItem('d30SelectedProduct', product.id)
  }

  return (
    <>
      <D30PricingCard
        id={products.fourMonth.id}
        title={fourMonthProductConfig?.title || '4-month subscription'}
        price={fourMonthProductConfig?.price || products.fourMonth.price}
        label={fourMonthProductConfig?.label}
        previousPrice={fourMonthProductConfig?.previousPrice || products.fourMonth.previousPrice}
        currency={fourMonthProductConfig?.currency || products.fourMonth.currency}
        isSelected={selectedCard === products.fourMonth}
        onClick={() => handleClick(products.fourMonth)}
        ctaText={fourMonthProductConfig?.ctaText || 'Subscribe now'}
        ctaLink={`/daily30/checkout?product=${products.fourMonth.id}`}
        featureList={fourMonthProductConfig?.featureList || products.fourMonth.featureList}
        tagline={fourMonthProductConfig?.tagline || 'ZOE members get an extra 15% off'}
        disabled={disabled}
        analyticsLinkText="4 month-Subscribe now"
      />
      <DSSpacer size={24} direction="vertical" />
      <D30PricingCard
        id={products.oneMonth.id}
        title={oneMonthProductConfig?.title || 'Monthly subscription'}
        price={oneMonthProductConfig?.price || products.oneMonth.price}
        currency={oneMonthProductConfig?.currency || products.oneMonth.currency}
        isSelected={selectedCard === products.oneMonth}
        onClick={() => handleClick(products.oneMonth)}
        ctaText={oneMonthProductConfig?.ctaText || 'Subscribe now'}
        ctaLink={`/daily30/checkout?product=${products.oneMonth.id}`}
        featureList={oneMonthProductConfig?.featureList || products.oneMonth.featureList}
        tagline={oneMonthProductConfig?.tagline || 'ZOE members get an extra 15% off'}
        disabled={disabled}
        analyticsLinkText="1 month-Subscribe now"
      />
    </>
  )
}
