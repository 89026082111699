import { FragmentComponent } from '@/types/graphql'
import React, { useEffect } from 'react'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { DSText } from '@zoe/ds-web'
import { trackChoosePlanViewEvent } from '@/lib/gtm/gtmV2'
import { PlanCardBlock, PlanCardRecord } from './PlanCardBlock'
import { PlanCards } from './PlanCards'

type MembershipPlanRecord = {
  __typename: 'MembershipPlanRecord'
  id?: string
  content: PlanCardRecord[]
}

const fragment = `
  fragment MembershipPlanFragment on MembershipPlanRecord {
    __typename
    id
    content {
      ...PlanCardFragment
    }
  }

  ${PlanCardBlock.fragment}
`

export const MembershipPlanBlock: FragmentComponent<{}, MembershipPlanRecord> = ({ record }) => {
  const { checkoutCountry } = useCheckoutCountry()
  useEffect(() => {
    trackChoosePlanViewEvent('Membership PDP')
  }, [])

  return checkoutCountry ? (
    <PlanCards plans={record?.content} />
  ) : (
    <DSText variant="fluid-paragraph-400" as="p" align="center">
      ZOE membership is not available in your country yet
    </DSText>
  )
}

MembershipPlanBlock.fragment = fragment
MembershipPlanBlock.recordName = 'MembershipPlanRecord'
