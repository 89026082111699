import { SeoMetadata } from 'types'
import Head from 'next/head'

interface SEOMetaTagsProps {
  metaData: SeoMetadata
  path: string
  type?: string
}

export const SEOMetaTags: React.FC<SEOMetaTagsProps> = ({ metaData, path, type }) => {
  return (
    <Head>
      {metaData?.title && (
        <>
          <title>{metaData.title}</title>
          <meta property="og:title" content={metaData.title} key="title" />
          <meta name="twitter:title" content={metaData.title} key="twitter-title" />
        </>
      )}
      {metaData?.description && (
        <>
          <meta name="description" content={metaData.description} key="description" />
          <meta property="og:description" content={metaData.description} key="og-description" />
          <meta name="twitter:description" content={metaData.description} key="twitter-description" />
        </>
      )}
      {metaData?.image && (
        <>
          <meta property="og:image" content={metaData.image?.responsiveImage?.src} key="og-image" />
          <meta name="twitter:image" content={metaData.image?.responsiveImage?.src} key="twitter-image" />
        </>
      )}
      {path && (
        <>
          <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}${path}`} key="canonical" />
          <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${path}`} key="og-url" />
        </>
      )}
      <meta property="og:type" content={type || 'website'} key="type" />
      {metaData?.twitterCard && (
        <>
          <meta name="twitter:site" content="@Join_ZOE" />
          <meta name="twitter:card" content={metaData.twitterCard} />
        </>
      )}
      {metaData?.noIndex && <meta name="robots" content="noindex,nofollow" key="robots" />}
    </Head>
  )
}
