import React from 'react'
import { FragmentComponent } from '@/types/graphql'
import { PlanCard } from '@/components/elements/plan-card/PlanCard'
import { ResponsiveImageType } from 'react-datocms'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'

export type PlanCardHeaderBackgroundColor = 'navy-subtle' | 'yellow-bold' | 'navy-bold' | undefined
export type PlanCardRecord = {
  __typename: 'PlanCardRecord'
  id?: string
  title: string
  tagline?: string
  badge?: string
  headerBackgroundColor?: PlanCardHeaderBackgroundColor
  features?: PlanFeatureGroupBlock[]
  priceSection?: PlanPriceSectionBlock
  footnote?: string
}

export type PlanFeatureGroupBlock = {
  __typename: 'PlanFeatureGroupBlock'
  id?: string
  title: string
  features: PlanFeatureBlock[]
}

export type PlanFeatureBlock = {
  __typename: 'PlanFeatureBlock'
  id?: string
  title: string
  description: string
  badge?: string
  image: { responsiveImage: ResponsiveImageType }
  excluded?: boolean
}

export type PlanPriceSectionBlock = {
  __typename: 'PlanPriceSectionBlock'
  id?: string
  planId: string
  membershipLabel: string
  kitLabel: string
  subscriptionLabel: string
  ctaLabel?: string
  ctaAriaLabel?: string
}

const planFeatureFragment = `
    fragment PlanFeatureFragment on PlanFeatureRecord {
      __typename
      id
      title
      description
      badge
      image {
        ${responsiveImageBlock({ width: 40 })}
      }
      excluded
    }
  `

const planFeatureGroupFragment = `
    fragment PlanFeatureGroupFragment on PlanFeatureGroupRecord {
      __typename
      id
      title
      features {
        ...PlanFeatureFragment
      }
    }
    ${planFeatureFragment}
    
  `

const planPriceSectionFragment = `
  fragment PlanPriceSectionFragment on PlanPriceSectionRecord {
    __typename
    id
    planId
    membershipLabel
    kitLabel
    subscriptionLabel
    ctaLabel
    ctaAriaLabel
  }
`
const fragment = `
  fragment PlanCardFragment on PlanCardRecord {
    __typename
    id
    title
    tagline
    badge
    headerBackgroundColor
    features {
      ...PlanFeatureGroupFragment
    }
    priceSection {
      ...PlanPriceSectionFragment
    }
    footnote
  }
  ${planFeatureGroupFragment}
  ${planPriceSectionFragment}
`

export const PlanCardBlock: FragmentComponent<{}, PlanCardRecord> = ({ record }) => {
  return (
    <PlanCard
      id={record?.title?.toLowerCase()}
      title={record?.title}
      headerBackgroundColor={record?.headerBackgroundColor}
      tagline={record?.tagline}
      badge={record?.badge}
      featureGroups={record?.features}
      priceSection={record?.priceSection}
      footnote={record?.footnote}
    />
  )
}

PlanCardBlock.fragment = fragment
PlanCardBlock.recordName = 'PlanCardRecord'
