import React from 'react'
import styled from 'styled-components'
import { DSText } from '@zoe/ds-web'
import { FragmentComponent } from '@/types/graphql'

type LabelImageRecord = {
  __typename: 'LabelImageRecord'
  id?: string
  text: string
  colour: string
  image: string
}

const fragment = `
  fragment LabelImageFragment on LabelImageRecord {
    __typename
    id
    text
    colour
    image
  }
`

const StyledContainer = styled.div<{ $colour: string }>`
  display: inline-flex;
  align-items: center;
  background-color: ${({ $colour }) => `var(--color-surface-${$colour})`};
  border-radius: var(--radius-16);
  padding: var(--grid-2) var(--grid-16) var(--grid-2) var(--grid-48);
  position: relative;
`

const StyledImage = styled.img`
  position: absolute;
  top: -8px;
  left: 6px;
  width: 40px;
  height: auto;
`

export const LabelImage: FragmentComponent<{}, LabelImageRecord> = ({ record }) => {
  return (
    <StyledContainer $colour={record.colour} aria-label={record.text}>
      <DSText variant="fluid-label-100" weight="semiBold" align="center" as="span">
        {record.text}
      </DSText>
      <StyledImage src={`/assets/img/label/${record.image}.png`} aria-hidden />
    </StyledContainer>
  )
}

LabelImage.fragment = fragment
LabelImage.recordName = 'LabelImageRecord'
