import { DSFlex, FlexAlignItems } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText, DSTextFontFamilyType, DSTextColorType, DSTextVariantType } from '@zoe/ds-web'
import { DSIcon, DSIconColorType, DSIconName, DSIconSize } from '@zoe/ds-web'

type TextAndIconProps = {
  text: string
  textVariant: DSTextVariantType
  textWeight: DSTextFontFamilyType
  textColour: DSTextColorType
  iconName: DSIconName
  iconSize: DSIconSize
  iconPosition: 'left' | 'right'
  iconColour?: DSIconColorType
  iconAndTextAlignment?: FlexAlignItems
}

export const DSTextAndIcon = ({
  text,
  textColour,
  textVariant,
  textWeight,
  iconName,
  iconSize,
  iconPosition,
  iconColour,
  iconAndTextAlignment = 'center',
}: TextAndIconProps) => {
  return (
    <DSFlex direction="row" justifyContent="flex-start" alignItems={iconAndTextAlignment}>
      <DSFlex direction={iconPosition === 'right' ? 'row' : 'row-reverse'} alignItems={iconAndTextAlignment}>
        <DSText as="span" variant={textVariant} weight={textWeight} color={textColour}>
          {text}
        </DSText>
        <DSSpacer size={8} direction="horizontal" />
        <DSIcon name={iconName} size={iconSize} color={iconColour} />
      </DSFlex>
    </DSFlex>
  )
}
