import { FragmentComponent } from '@/types/graphql'
import { DSSpacer, DSText } from '@zoe/ds-web'
import { styled } from 'styled-components'
import { HomepageScienceIcon } from './HomepageScienceIcon'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { HomepageScienceIconGridRecord } from 'types'

const fragment = `
  fragment HomepageScienceIconGridFragment on HomepageScienceIconGridRecord {
    __typename
    id
    content {
      id
      icon
      label
    }
  }
`

const StyledIconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const StyledIconTextContainer = styled.div`
  padding: var(--grid-16) 0;

  border-style: solid;
  border-color: var(--color-border-teal);
  border-width: 0;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    border-right-width: 1px;
  }

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom-width: 1px;
    }

    &:nth-child(2) {
      border-right-width: 0;
    }

    &:nth-child(1),
    &:nth-child(3) {
      border-right-width: 1px;
    }
  }
`

const StyledIconTextContentContainer = styled.div`
  max-width: 130px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HomepageScienceIconGrid: FragmentComponent<{}, HomepageScienceIconGridRecord> = ({ record }) => {
  return (
    <StyledIconGrid>
      {record.content.map((item) => (
        <StyledIconTextContainer key={item.id}>
          <StyledIconTextContentContainer>
            <HomepageScienceIcon iconName={item.icon} />
            <DSSpacer size={12} direction="vertical" />
            <DSText variant="fluid-paragraph-200" align="center">
              {item.label}
            </DSText>
          </StyledIconTextContentContainer>
        </StyledIconTextContainer>
      ))}
    </StyledIconGrid>
  )
}

HomepageScienceIconGrid.fragment = fragment
HomepageScienceIconGrid.recordName = 'HomepageScienceIconGridRecord'
