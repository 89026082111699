import { GeoContext } from '@/context/geoContext'
import { FragmentComponent } from '@/types/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { renderNodeRule, StructuredText } from 'react-datocms'
import { AccordionRecord } from 'types'
import { HomepageAccordionGalleryBlock } from '@/components/pages/homepage/content/HomepageAccordionGalleryBlock'
import { StyledOverrideDefaultStyles } from '@/blocks/DesignSystem/DSStyledStructuredText'
import { TrackingContext } from '@/context/trackingContext'
import { DSSpacer, DSText, DSAccordion, DSFlex, DSIcon, DSIconName, DSSurfaceColorType } from '@zoe/ds-web'
import { getBreakpointForWidth } from '@/components/ds/breakpoints'
import { IngredientFAQBody } from '@/components/pages/daily30/faq/IngredientFAQBody'
import { isLink, isParagraph } from 'datocms-structured-text-utils'
import styled from 'styled-components'

const fragment = `
  fragment AccordionBlockFragment on AccordionRecord {
    __typename
    id
    asGallery
    expandFirstSection
    variant
    items {
      title
      content {
        value
      }
      titleIcon
      iconBackgroundColour
      imageContent {
        ...SimpleImageBlockFragment
      }
    }
  }
`

const StyledIconBackground = styled.div<{ $backgroundColour?: string }>`
  ${({ $backgroundColour }) => $backgroundColour && `background-color: var(--colour-surface-${$backgroundColour});`}
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const renderIcon = (icon: DSIconName, iconBackground?: DSSurfaceColorType): React.ReactNode => {
  const iconContent = (
    <DSFlex direction="column" justifyContent="center">
      <DSIcon name={icon} size={iconBackground ? 16 : 24} />
    </DSFlex>
  )

  return (
    <>
      {iconBackground ? (
        <StyledIconBackground $backgroundColour={iconBackground}>{iconContent}</StyledIconBackground>
      ) : (
        iconContent
      )}
      <DSSpacer direction="horizontal" size={16} />
    </>
  )
}

export const renderSectionTitle = (title: string): React.ReactNode => {
  // eslint-disable-next-line prefer-named-capture-group -- we need the capture and named capture is only supported when targeting ES2018 or later.
  const boldRegex = /^\*\*(.*)\*\*$/
  const match = boldRegex.exec(title)

  return match ? (
    <DSText as="p" variant="fluid-paragraph-300" weight="regular">
      {match[1]}
    </DSText>
  ) : (
    <DSText as="p" variant="fluid-paragraph-300" weight="light">
      {title}
    </DSText>
  )
}

export const AccordionBlock: FragmentComponent<{}, AccordionRecord> = ({ record }) => {
  const { country } = useContext(GeoContext)
  const { trackAccordionClick } = useContext(TrackingContext)
  const [expandFirstSection, setExpandFirstSection] = useState<boolean>()

  useEffect(() => {
    const breakpoint = getBreakpointForWidth(window.innerWidth)
    const shouldExpand = ['s', 'm', 'l', 'xl'].some(
      (bp: any) => (record.expandFirstSection ?? []).includes(bp) && breakpoint === bp,
    )

    setExpandFirstSection(shouldExpand)
  }, [record.expandFirstSection])

  if (expandFirstSection === undefined) {
    return null
  }

  if (record.asGallery) {
    return <HomepageAccordionGalleryBlock record={record} expandFirstSection={expandFirstSection} />
  }

  return (
    <DSAccordion
      items={record?.items?.map(({ title, content, titleIcon, iconBackgroundColour }, index) => ({
        title: (
          <DSFlex alignItems="center" direction="row" justifyContent="flex-start">
            {titleIcon && renderIcon(titleIcon as DSIconName, iconBackgroundColour as DSSurfaceColorType)}
            {renderSectionTitle(title)}
            <DSSpacer direction="horizontal" size={32} />
          </DSFlex>
        ),
        content: (
          <StyledOverrideDefaultStyles>
            <StructuredText
              data={content.value}
              customNodeRules={[
                renderNodeRule(isLink, ({ node, children }) => {
                  if (node.url.includes('[D30IngredientFAQModal]')) {
                    return <IngredientFAQBody linkLabel={children} country={country} />
                  } else {
                    const metaAttributes = node.meta?.reduce((acc, meta) => {
                      acc[meta.id] = meta.value
                      return acc
                    }, {})
                    return (
                      <a href={node.url} {...metaAttributes}>
                        {children}
                      </a>
                    )
                  }
                }),
                renderNodeRule(isParagraph, ({ children }) => (
                  <>
                    <p>{children}</p>
                    <DSSpacer direction="vertical" size={16} />
                  </>
                )),
              ]}
            />
          </StyledOverrideDefaultStyles>
        ),
        id: index.toString(),
      }))}
      variant={record.variant || 'default'}
      onItemClick={() => trackAccordionClick(record.id ?? '')}
      expandFirstSection={expandFirstSection}
    />
  )
}

AccordionBlock.fragment = fragment
AccordionBlock.recordName = 'AccordionRecord'
