import { Field, useField } from 'formik'
import styled from 'styled-components'

const StyledInput = styled(Field)`
  background-color: var(--color-surface-input);
  border: 1px solid var(--color-border-input);
  border-radius: var(--radius-4);
  color: var(--color-text-input);
  font-family: var(--font-sans-serif-light);
  font-size: var(--font-size-input);
  line-height: var(--line-height-input);
  outline: none;
  padding: var(--padding-input-y) var(--padding-input-x);
  transition: border 0.3s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid var(--color-border-input-focus);
  }

  &:disabled {
    background-color: var(--color-surface-input-disabled);
  }

  &::placeholder {
    color: var(--color-text-input-placeholder);
  }

  ${(props) =>
    props.error &&
    `
    border-color: var(--color-border-error);
  `}// If mobile styling needed, make sure to add to formFieldStyles.ts, not here
`

const StyledInputGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const StyledRightContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
`

// https://formik.org/docs/api/field
// Think about migrating away from Formik to use https://react-spectrum.adobe.com/react-aria/TextField.html#input

export interface DSInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string // Ensure name is required for error state checking,
  rightContent?: React.ReactNode
  autoComplete?: 'username' | 'current-password' | 'new-password' | 'on' // For password managers
}

export const DSInput = (props: DSInputProps) => {
  const [field, meta] = useField(props.name)
  if (!props.autoComplete) {
    props = { ...props, autoComplete: 'on' }
  }
  return props.rightContent ? (
    <StyledInputGroup>
      <StyledInput {...field} {...props} error={meta.error} />
      <StyledRightContent>{props.rightContent}</StyledRightContent>
    </StyledInputGroup>
  ) : (
    <StyledInput {...field} {...props} error={meta.error} />
  )
}
