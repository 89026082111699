import { DSSpacer, DSText } from '@zoe/ds-web'
import { IngredientChain } from './D30IngredientSection'
import styled, { keyframes } from 'styled-components'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { processTypographyMarkdown } from '@/components/blocks/DesignSystem/Typography'
import { AccordionBlock } from '@/components/blocks/DesignSystem/Accordion'

const fragment = `
  fragment D30IngredientListBlockFragment on D30IngredientListRecord {
    __typename
    id
    preTitle
    title
    description
    ingredientList {
      ...AccordionBlockFragment
    }
  }
  `

const slideInRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

const slideInLeft = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`

const StyledAnimatedRight = styled.div`
  animation: ${slideInRight} 160s linear infinite;
  width: 400%;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInRight} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInRight} 30s linear infinite;
  }
`

const StyledAnimatedLeft = styled.div`
  animation: ${slideInLeft} 160s linear infinite;
  width: 400%;
  position: absolute;
  top: 155px;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInLeft} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInLeft} 30s linear infinite;
  }
`

const StyledAnimationsContainer = styled.div`
  position: relative;
  height: 350px;
`

const ingredientImageUrls = [
  '/assets/img/daily30/ingredients/lions-mane.png',
  '/assets/img/daily30/ingredients/cordiceps.png',
  '/assets/img/daily30/ingredients/reishi.png',
  '/assets/img/daily30/ingredients/quinoa.png',
  '/assets/img/daily30/ingredients/turmeric.png',
  '/assets/img/daily30/ingredients/white-mushroom.png',
  '/assets/img/daily30/ingredients/shiitake.png',
  '/assets/img/daily30/ingredients/fresh-lions-mane.png',
  '/assets/img/daily30/ingredients/beetroot.png',
  '/assets/img/daily30/ingredients/tremella.png',
  '/assets/img/daily30/ingredients/seaweed.png',
  '/assets/img/daily30/ingredients/rosemary.png',
]

export const D30IngredientListBlock = ({ record }) => {
  const midIndex = Math.ceil(ingredientImageUrls.length / 2)
  const firstHalf = ingredientImageUrls.slice(0, midIndex)
  const secondHalf = ingredientImageUrls.slice(midIndex)
  return (
    <>
      <DSText as="h5" variant="fluid-paragraph-200" align="center" weight="semiBold">
        {record?.preTitle}
      </DSText>
      <DSSpacer direction="vertical" size={12} />
      <DSText as="h2" variant="fluid-heading-700" weight="book" align="center">
        {processTypographyMarkdown({ content: record?.title, variant: 'fluid-heading-700' })}
      </DSText>
      <DSSpacer direction="vertical" size={12} />
      <DSText as="p" variant="fluid-paragraph-300" weight="light" align="center">
        {record?.description}
      </DSText>
      <DSSpacer direction="vertical" size={24} />
      <StyledAnimationsContainer>
        <StyledAnimatedRight>
          <IngredientChain images={firstHalf} />
        </StyledAnimatedRight>
        <StyledAnimatedLeft>
          <IngredientChain images={secondHalf} />
        </StyledAnimatedLeft>
      </StyledAnimationsContainer>
      <DSSpacer direction="vertical" size={32} />
      {record?.ingredientList && (
        <div style={{ maxWidth: BREAKPOINTS.m, margin: '0 auto' }}>
          <AccordionBlock record={record?.ingredientList} />
        </div>
      )}
    </>
  )
}

D30IngredientListBlock.fragment = fragment
D30IngredientListBlock.recordName = 'D30IngredientListRecord'
