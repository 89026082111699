import styled from 'styled-components'

const StyledLoadingSpinner = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  span {
    width: 48px;
    height: 48px;
    border: 5px solid ${(props) => props.theme.v2.onSurface.text};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 0 auto;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .dots {
    width: 56px;
    height: 26.9px;
    background:
      radial-gradient(circle closest-side, var(--color-surface-yellow-bold) 90%, #0000) 0% 50%,
      radial-gradient(circle closest-side, var(--color-surface-yellow-bold) 90%, #0000) 50% 50%,
      radial-gradient(circle closest-side, var(--color-surface-yellow-bold) 90%, #0000) 100% 50%;
    background-size: calc(100% / 3) 13.4px;
    background-repeat: no-repeat;
    animation: dots-7ar3yq 1.4s infinite linear;
  }

  @keyframes dots-7ar3yq {
    20% {
      background-position:
        0% 0%,
        50% 50%,
        100% 50%;
    }

    40% {
      background-position:
        0% 100%,
        50% 0%,
        100% 50%;
    }

    60% {
      background-position:
        0% 50%,
        50% 100%,
        100% 0%;
    }

    80% {
      background-position:
        0% 50%,
        50% 50%,
        100% 100%;
    }
  }
`
export const LoadingSpinner = () => (
  <StyledLoadingSpinner>
    <div className="dots"></div>
  </StyledLoadingSpinner>
)
