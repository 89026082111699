import { FragmentComponent } from '@/types/graphql'
import React from 'react'
import { DSIconGrid, DSIconColorType, DSIconName, DSTextColorType } from '@zoe/ds-web'
import { GridSurfaceColour } from '@/components/ds/Grid/Grid'

type IconGridItemRecord = {
  __typename: 'IconGridItemRecord'
  id: string
  label: string
  icon: DSIconName
}

type IconGridRecord = {
  __typename: 'IconGridRecord'
  id: string
  surfaceColor: GridSurfaceColour
  textColor: DSTextColorType
  iconColor: DSIconColorType
  cells: IconGridItemRecord[]
}

const iconGridItemFragment = `
  fragment IconGridItemFragment on IconGridItemRecord {
    __typename
    id
    label
    icon
  }
`

const fragment = `
  fragment IconGridBlockFragment on IconGridRecord {
    __typename
    id
    surfaceColor
    textColor
    iconColor
    cells {
      ...IconGridItemFragment
    }
  }

  ${iconGridItemFragment}
`

export const IconGridBlock: FragmentComponent<{}, IconGridRecord> = ({ record }) => {
  return (
    <DSIconGrid
      cells={record?.cells}
      surfaceColor={record?.surfaceColor}
      textColor={record?.textColor}
      iconColor={record?.iconColor}
    />
  )
}

IconGridBlock.fragment = fragment
IconGridBlock.recordName = 'IconGridRecord'
