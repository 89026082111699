import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { DSSlider } from '@zoe/ds-web'
import { useEffect, useState } from 'react'
import { Image } from 'react-datocms/image'

const fragment = `
  fragment D30GalleryBlockFragment on D30GalleryRecord {
    __typename
    id
    images {
      ${responsiveImageBlock({ width: 620, height: 620, fit: 'fill' })}
    }
  }
`

export const D30GalleryBlock = ({ record }) => {
  const [viewportWidth, setViewportWidth] = useState(0)

  useEffect(() => {
    setViewportWidth(window?.innerWidth)
    const handleResize = () => setViewportWidth(window?.innerWidth)
    window?.addEventListener('resize', handleResize)

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  const getSlideWidth = () => {
    const slideGap = 112 // 16px gap + (2 * 48px padding)
    const minSlideWidth = 200
    const maxSlideWidth = 300

    const fittedSlideWidth = (viewportWidth - slideGap * 2) / 3

    if (fittedSlideWidth < minSlideWidth) {
      return maxSlideWidth
    }

    return Math.min(fittedSlideWidth, maxSlideWidth)
  }

  return (
    <DSSlider slideWidth={getSlideWidth()}>
      {record?.images?.map((img, index) => (
        <div key={index} style={{ borderRadius: 'var(--radius-8)', overflow: 'hidden' }}>
          <Image data={img.responsiveImage} key={index} />
        </div>
      ))}
    </DSSlider>
  )
}

D30GalleryBlock.fragment = fragment
D30GalleryBlock.recordName = 'D30GalleryBlockRecord'
