import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import { Logo } from '@/components/shared/Logo'
import { D30Table } from '../blocks/D30Table'

export const NutritionalContentModalUS = () => {
  return (
    <>
      <Logo colour="dark" />

      <DSSpacer size={24} direction="vertical" />

      <DSText variant="fluid-heading-800">Supplement Facts</DSText>

      <DSSpacer size={24} direction="vertical" />

      <DSText variant="fluid-label-100">
        <strong>Serving size:</strong> 1 scoop (about 1 heaped tablespoon, 15g)
      </DSText>
      <DSText variant="fluid-label-100">
        <strong>Servings per container:</strong> about 33
      </DSText>

      <DSSpacer size={24} direction="vertical" />

      <D30Table
        record={{
          __typename: 'TableRecord',
          id: '1',
          table: {
            columns: ['', 'Amount per serving', '% Daily Value'],
            data: [
              {
                '': '**Calories**',
                'Amount per serving': '60',
                '% Daily Value': '',
              },
              {
                '': '**Total fat**',
                'Amount per serving': '4g',
                '% Daily Value': '5%',
              },
              {
                '': 'Ploysat fat and monounsat fat',
                'Amount per serving': '3.6g',
                '% Daily Value': '',
              },
              {
                '': 'Saturated fat',
                'Amount per serving': '0.4g',
                '% Daily Value': '',
              },
              {
                '': '**Total Carbohydrate**',
                'Amount per serving': '3g',
                '% Daily Value': '1%*',
              },
              {
                '': 'Dietary fiber',
                'Amount per serving': '5g',
                '% Daily Value': '18%',
              },
              {
                '': '**Protein**',
                'Amount per serving': '3g',
                '% Daily Value': '',
              },
              {
                '': '**Thiamin (Vitamin B1)**',
                'Amount per serving': '0.1mg',
                '% Daily Value': '8%',
              },
              {
                '': '**Riboflavin (Vitamin B2)**',
                'Amount per serving': '0.1mg',
                '% Daily Value': '8%',
              },
              {
                '': '**Niacin**',
                'Amount per serving': '1mg NE',
                '% Daily Value': '6%',
              },
              {
                '': '**Vitamin B6**',
                'Amount per serving': '0.1mg',
                '% Daily Value': '6%',
              },
              {
                '': '**Folate**',
                'Amount per serving': '20 mcg DFE',
                '% Daily Value': '5%',
              },
              {
                '': '**Pantothenic Acid**',
                'Amount per serving': '0.5mg',
                '% Daily Value': '10%',
              },
              {
                '': '**Calcium**',
                'Amount per serving': '40mg',
                '% Daily Value': '3%',
              },
              {
                '': '**Iron**',
                'Amount per serving': '1.2mg',
                '% Daily Value': '7%',
              },
              {
                '': '**Magnesium**',
                'Amount per serving': '40mg',
                '% Daily Value': '10%',
              },
              {
                '': '**Zinc**',
                'Amount per serving': '0.8mg',
                '% Daily Value': '7%',
              },
              {
                '': '**Selenium**',
                'Amount per serving': '5mcg',
                '% Daily Value': '9%',
              },
              {
                '': '**Sodium**',
                'Amount per serving': '10mg',
                '% Daily Value': '<1%%',
              },
              {
                '': '**Potassium**',
                'Amount per serving': '120mg',
                '% Daily Value': '3%',
              },
              {
                '': '**Omega 3 fatty acids**',
                'Amount per serving': '0.3g',
                '% Daily Value': '†',
              },
            ],
          },
        }}
      />

      <DSText variant="fluid-label-100">Docosahexaenoic (DHA),</DSText>
      <DSText variant="fluid-label-100">Eicosapentaenoic Acid (EPA),</DSText>
      <DSText variant="fluid-label-100">Alpha-Linoleic Acid (ALA)</DSText>
    </>
  )
}
