import React from 'react'
import { PlanCardBlock, PlanCardRecord } from './PlanCardBlock'
import styled from 'styled-components'

type PlanCardsType = {
  plans: PlanCardRecord[]
}

const StyledPlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--grid-24);
  overflow-scroll-behavior: smooth;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
  overflow-x: auto;
  padding: var(--grid-16);

  // Cards are 320px + 24px gap, we break when viewport is too small
  @media screen and (max-width: 1008px) {
    overflow-x: scroll;
    justify-content: flex-start;
    margin-top: 0;
  }
`
export const PlanCards = ({ plans }: PlanCardsType) => {
  return (
    <StyledPlansContainer>
      {plans.map((plan, i) => (
        <PlanCardBlock key={`plan-${i}`} record={plan} />
      ))}
    </StyledPlansContainer>
  )
}
