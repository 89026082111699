import { FragmentComponent } from '@/types/graphql'
import { DividerRecord } from '../../../types'
import React from 'react'
import { DSDivider } from '@zoe/ds-web'

const fragment = `
  fragment DividerBlockFragment on DividerRecord {
    __typename
    id
    content
    height
    variant
    verticalSpacing
    width
  }
`

export const DividerBlock: FragmentComponent<{}, DividerRecord> = ({ record }) => {
  return (
    <DSDivider
      content={record?.content}
      height={record?.height}
      variant={record?.variant}
      spacing={record?.verticalSpacing}
      width={record?.width}
    />
  )
}

DividerBlock.fragment = fragment
DividerBlock.recordName = 'DividerRecord'
