import { DSModal } from '@zoe/ds-web'
import { useState } from 'react'
import { NutritionalContentModalGB } from './NutritionalContentModalGB'
import { NutritionalContentModalUS } from './NutritionalContentModalUS'
import { CHECKOUT_US } from '@/lib/checkout/types'

export const IngredientFAQBody = ({ linkLabel, country }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <a onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer' }}>
        {linkLabel}
      </a>
      <DSModal
        isOpen={isModalOpen}
        surfaceColor="teal-subtle"
        onBackdropClick={() => setIsModalOpen(false)}
        onCloseButtonClick={() => setIsModalOpen(false)}
      >
        {country === CHECKOUT_US ? <NutritionalContentModalUS /> : <NutritionalContentModalGB />}
      </DSModal>
    </>
  )
}
