import { DSTextAndIcon } from '@/components/ds/TextAndIcon/TextAndIcon'
import styled from 'styled-components'
import { BREAKPOINTS } from '@/components/ds/breakpoints'

const fragment = `
  fragment D30FeatureListBlockFragment on D30FeatureListRecord {
    __typename
    id
    features
  }
`
const StyledFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: start;

  @media (max-width: ${BREAKPOINTS.m}) {
    gap: 16px;
  }
`
export const D30FeatureListBlock = ({ record }) => {
  return (
    record?.features && (
      <StyledFeaturesContainer>
        {record?.features.map((feature) => (
          <DSTextAndIcon
            text={feature}
            textVariant="fluid-paragraph-200"
            textWeight="regular"
            textColour="primary"
            iconName="tick-in-star"
            iconColour="brand"
            iconPosition="left"
            iconSize={24}
            iconAndTextAlignment="center"
            key={feature}
          />
        ))}
      </StyledFeaturesContainer>
    )
  )
}

D30FeatureListBlock.fragment = fragment
D30FeatureListBlock.recordName = 'D30FeatureListBlockRecord'
