import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StyledCarouselItem = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
  max-width: 450px;
  text-align: left;

  img {
    border-radius: var(--grid-16);
  }
`

const StyledDescriptionWrapper = styled.div`
  padding-right: var(--grid-6);
`

interface HomepageCarouselItemProps {
  image: ReactNode
  title?: string
  description?: ReactNode | string
  containerWidth?: number
}

export const CarouselItem = ({ image, title, description, containerWidth = 450 }: HomepageCarouselItemProps) => {
  const descriptionElement =
    description && typeof description === 'string' ? (
      <DSText variant="fluid-paragraph-200" weight="light" align="left">
        {description}
      </DSText>
    ) : (
      description
    )

  return (
    <StyledCarouselItem $width={containerWidth}>
      <DSFlex direction="column">
        {image}
        <DSSpacer size={24} direction="vertical" />

        {title ? (
          <DSText variant="fluid-heading-600" weight="book" align="left">
            {title}
          </DSText>
        ) : null}
        <DSSpacer size={8} direction="vertical" />
        {descriptionElement ? <StyledDescriptionWrapper>{descriptionElement}</StyledDescriptionWrapper> : null}
      </DSFlex>
    </StyledCarouselItem>
  )
}
