import { StructuredText } from 'react-datocms'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSShow } from '@/components/ds/Responsive/Show/Show'
import styled from 'styled-components'
import { BREAKPOINTS, getBreakpointForWidth } from '@/components/ds/breakpoints'
import { AccordionItemRecord, AccordionRecord } from '../../../../types'
import { StyledOverrideDefaultStyles } from '@/blocks/DesignSystem/DSStyledStructuredText'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { TrackingContext } from '@/context/trackingContext'
import { DSAccordionItemProps, DSAccordion, DSSurfaceColorType, DSIconName } from '@zoe/ds-web'
import { renderIcon, renderSectionTitle } from '@/components/blocks/DesignSystem/Accordion'

const StyledAccordionSection = styled.div`
  padding-right: 10%;
  width: 50%;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    padding-right: 0;
    width: 100%;
  }

  img {
    border-radius: var(--grid-16);
  }
`

const StyledDesktopImage = styled.div`
  width: 50%;

  img {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: var(--grid-16);
  }

  img.fade-in {
    opacity: 1;
  }

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    display: none;
  }
`

const IMAGE_FADE_OUT_DURATION = 300

export const HomepageAccordionGalleryBlock = ({ record, expandFirstSection }) => {
  const { trackAccordionClick } = useContext(TrackingContext)

  const items = useMemo(
    () =>
      record.items.map((item: AccordionItemRecord, i: number) => {
        item.id = i.toString()
        return item
      }),
    [record.items],
  )

  const [selectedItemImage, setSelectedItemImage] = useState(items[0]?.imageContent?.image)
  const [isImageChanging, setIsImageChanging] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(true)

  const onSectionClick = (selectedSection: DSAccordionItemProps) => {
    const item = items.find((item) => item.id === selectedSection.id)
    const image = item?.imageContent?.image
    if (image && image?.responsiveImage?.src !== selectedItemImage?.responsiveImage?.src) {
      setIsImageChanging(true)
      setIsImageLoaded(false)
      setTimeout(() => {
        setSelectedItemImage(image)
      }, IMAGE_FADE_OUT_DURATION)
    }
  }

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true)
    setIsImageChanging(false)
  }, [])

  return (
    <DSFlex direction="row" justifyContent="space-between">
      <StyledAccordionSection>
        <DSAccordion
          onItemClick={(content) => {
            onSectionClick(content)
            trackAccordionClick(content?.title?.toString() || '')
          }}
          variant={record.variant || 'default'}
          expandFirstSection={expandFirstSection}
          items={items?.map(({ title, content, imageContent, titleIcon, iconBackgroundColour, id }) => ({
            title: (
              <DSFlex alignItems="center" direction="row" justifyContent="flex-start">
                {renderIcon(titleIcon as DSIconName, iconBackgroundColour as DSSurfaceColorType)}
                {renderSectionTitle(title)}
                <DSSpacer direction="horizontal" size={32} />
              </DSFlex>
            ),
            content: (
              <div>
                <DSShow below="m">
                  <DSFlex direction="row" justifyContent="center">
                    <Image
                      src={imageContent?.imageMobile?.responsiveImage.src}
                      alt={imageContent?.imageMobile?.responsiveImage.alt}
                      width={343}
                      height={343}
                    />
                  </DSFlex>
                  <DSSpacer size={24} direction="vertical" />
                </DSShow>
                <StyledOverrideDefaultStyles>
                  <StructuredText data={content.value} />
                </StyledOverrideDefaultStyles>
              </div>
            ),
            id,
          }))}
        />
      </StyledAccordionSection>
      <StyledDesktopImage>
        {selectedItemImage?.responsiveImage && (
          <DSShow above="s">
            <DSFlex direction="column" alignItems="flex-end">
              <Image
                src={selectedItemImage.responsiveImage.src}
                alt={selectedItemImage.responsiveImage.alt}
                width={521}
                height={521}
                className={isImageChanging ? '' : 'fade-in'}
                onLoad={handleImageLoad}
                loading="eager"
              />
            </DSFlex>
          </DSShow>
        )}
      </StyledDesktopImage>
    </DSFlex>
  )
}
