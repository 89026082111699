import { CHECKOUT_GB, CHECKOUT_US, Product, Products } from '@/lib/checkout/types'
import { getProduct } from '@/lib/checkout/products'

type PriceByCountry = {
  [CHECKOUT_GB]: Product
  [CHECKOUT_US]: Product
}

type CurrentMembershipPrices = {
  start: PriceByCountry
  core: PriceByCountry
  complete: PriceByCountry
}

export const getCurrentPrices = (products: Products) => {
  const memberships: CurrentMembershipPrices = {
    start: {
      [CHECKOUT_GB]: getProduct('GB_PROGRAM_4_MONTH_START_DEC24_MONTHLY_ROLLING', products),
      [CHECKOUT_US]: getProduct('US_PROGRAM_4_MONTH_START_DEC24_MONTHLY_ROLLING', products),
    },
    core: {
      [CHECKOUT_GB]: getProduct('GB_PROGRAM_12_MONTH_CORE_DEC24_MONTHLY_ROLLING', products),
      [CHECKOUT_US]: getProduct('US_PROGRAM_12_MONTH_CORE_DEC24_MONTHLY_ROLLING', products),
    },
    complete: {
      [CHECKOUT_GB]: getProduct('GB_PROGRAM_12_MONTH_COMPLETE_DEC24_MONTHLY_ROLLING', products),
      [CHECKOUT_US]: getProduct('US_PROGRAM_12_MONTH_COMPLETE_DEC24_MONTHLY_ROLLING', products),
    },
  }
  const kits: CurrentMembershipPrices = {
    start: {
      [CHECKOUT_GB]: getProduct('GB_TEST_KIT_START_DEC24', products),
      [CHECKOUT_US]: getProduct('US_TEST_KIT_START_DEC24', products),
    },
    core: {
      [CHECKOUT_GB]: getProduct('GB_TEST_KIT_CORE_DEC24', products),
      [CHECKOUT_US]: getProduct('US_TEST_KIT_CORE_DEC24', products),
    },
    complete: {
      [CHECKOUT_GB]: getProduct('GB_TEST_KIT_COMPLETE_DEC24', products),
      [CHECKOUT_US]: getProduct('US_TEST_KIT_COMPLETE_DEC24', products),
    },
  }

  return { memberships, kits }
}
